<template>
  <!-- About Section Start -->
  <section class="about-section pt-4">
    <div class="container">
      <div class="section-title">
        <h2>Pourquoi choisir eBDFRET ?</h2>
      </div>
      <div class="row align-items-center">
        <div
          class="col-lg-5 wow fadeInUp"
          data-wow-duration="1s"
        >
          <div class="about-img">
            <img
              src="@/assets/images/why.jpg"
              alt="about image"
            >
          </div>
        </div>
        <div class="col-lg-7">
          <div class="about-text">
            <ul
              id="myTab"
              class="nav nav-tabs"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  id="about-tab"
                  class="nav-link active"
                  data-toggle="tab"
                  href="#about"
                  role="tab"
                  aria-controls="about"
                  aria-selected="true"
                >A propos</a>
              </li>
            </ul>
            <div
              id="myTabContent"
              class="tab-content"
            >
              <div
                id="about"
                class="tab-pane fade show active"
                role="tabpanel"
                aria-labelledby="about-tab"
              >
                <p>L'objectif de eBDFRET est d'abord  d’être la plateforme de référence en matière de publication d'offre de transports pour les Affréteurs ensuite la mise à la disposition des transporteurs ou sociétés de transports, des offres de transports proposées par les affréteurs  et enfin  la réduction des trajets à vide pour les Transporteurs pour permettre aux transporteurs d'augmenter leur rentabilité en recevant des offres de livraisons au moment où ils en ont besoin.</p>
              </div>
            </div>
            <div class="theme-btn">
              <a
                href="about.html"
                class="default-btn"
              >En savoir plus</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- About Section End -->
</template>
<script>
export default {
  name: 'About',
}
</script>
