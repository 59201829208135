<template>
  <!-- Blog Section Start -->
  <section class="blog-section pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center pb-4">
        <h2>ACTUALITES</h2>
      </div>
      <div class="row ">
        <div
          class="col-lg-4 col-sm-6 wow fadeInUp"
          data-wow-duration="1s"
        >
          <div class="blog-card">
            <div class="blog-img">
              <a href="blog-details.html">
                <img
                  src="@/assets/images/car4.jpg"
                  alt="blog image"
                >
              </a>
            </div>
            <div class="blog-text">
              <h4>
                <a href="blog-details.html">Libéralisation du service Transport & Logistique</a>
              </h4>
              <ul>
                <li>
                  <i class="icofont-ui-calendar" />
                  01 avril, 2020
                </li>
              </ul>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
              <div class="blog-btn text-left">
                <a href="#">
                  <i class="icofont-rounded-double-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-sm-6 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".2s"
        >
          <div class="blog-card">
            <div class="blog-img">
              <a href="blog-details.html"><img
                src="@/assets/images/car3.jpg"
                alt="blog image"
              ></a>
            </div>
            <div class="blog-text">
              <h4>
                <a href="blog-details.html">Nouveaux véhicules dans la flotte - Découvrez nos nouveaux camions Scania</a>
              </h4>
              <ul>
                <li>
                  <i class="icofont-ui-calendar" />
                  02 April, 2020
                </li>
              </ul>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
              <div class="blog-btn text-left">
                <a href="#">
                  <i class="icofont-rounded-double-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".2s"
        >
          <div class="blog-card">
            <div class="blog-img">
              <a href="blog-details.html"><img
                src="@/assets/images/car1.jpg"
                alt="blog image"
              ></a>
            </div>
            <div class="blog-text">
              <h4>
                <a href="blog-details.html">Notre chauffeur confirme que le camion a été réparé dès que possible</a>
              </h4>
              <ul>
                <li>
                  <i class="icofont-ui-calendar" />
                  03 April, 2020
                </li>
              </ul>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
              <div class="blog-btn text-left">
                <a href="#">
                  <i class="icofont-rounded-double-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Blog Section End -->
</template>
<script>
export default {
  name: 'Blog',
}
</script>
