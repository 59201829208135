<template>
  <!-- Transport System Section Start -->
  <section class="transport-section pt-4">
    <div class="container">
      <div class="section-title text-center">
        <h2>Nos Services</h2>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-sm-6 wow fadeInUp"
          data-wow-duration="1s"
        >
          <div class="transport-card">
            <div class="transport-img">
              <img
                src="@/assets/images/car1.jpg"
                alt="transport image"
              >
            </div>
            <div class="transport-text">
              <i class="icofont-truck" />
              <h3>Annonce de fret</h3>
              <p>Expédiez plus vite et gagnez du temps.
                Avec eBDFRET trouver un transporteur fiable et digne de confiance en temps réel.
                Suivez chaque étape en ligne, de la publication de votre offre,
                à la livraison  facilement  </p><br><br>

              <div class="theme-btn">
                <router-link
                  to="/affreteur"
                  class="default-btn"
                >
                  En savoir plus
                </router-link>

              </div>

            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-sm-6 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".3s"
        >
          <div class="transport-card">
            <div class="transport-img">
              <img
                src="@/assets/images/trans.jpg"
                alt="transport image"
              >
            </div>
            <div class="transport-text">
              <i class="icofont-truck-loaded" />
              <h3>Opportunités de transport</h3>
              <p>Avec eBDFRET trouver des offres variées et adaptées à votre  moyen de transport
                publier chaque jour par des affréteurs fiables.Vous  êtes une société de
                transports ou même un transporteur indépendant? Alors eBFRET est la plateforme
                qu'il vous faut.
              </p>
              <div class="theme-btn">
                <router-link
                  to="/transporteur"
                  class="default-btn"
                >
                  En savoir plus
                </router-link>

              </div>

            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".6s"
        >
          <div class="transport-card">
            <div class="transport-img">
              <img
                src="@/assets/images/car3.jpg"
                alt="transport image"
              >
            </div>
            <div class="transport-text">
              <i class="icofont-truck-alt" />
              <h3>Transport d'occasion</h3>
              <p>Ne roulez plus à vide.Grâce à notre plateforme eBdfret ,trouver tous les jours des opportunités de transports peu importe votre position pour combler
                vos retours de mission vide pour augmenter votre marge de revenue.
              </p><br>
              <div class="theme-btn">
                <router-link
                  to="/transporteur"
                  class="default-btn"
                >
                  En savoir plus
                </router-link>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Transport System Section End -->
</template>
<script>
export default {
  name: 'TransportSection',
}
</script>
<style>
p
{
 text-align:justify;
}
</style>
