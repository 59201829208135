<template>

  <div>

    <b-container class="bv-example-row container tables">

      <b-row>
        <b-col />
        <!-- <b-col lg="12"><b-table responsive  striped hover :items="items" :fields="fields" class="tblrow"></b-table></b-col> -->
        <b-col />
      </b-row>
    </b-container>

  </div>

</template>

<script>
import {
  BRow, BCol, BContainer,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BContainer,
    BRow,
  },
  data() {
    return {
      // Note `isActive` is left out and will not appear in the rendered table
      fields: ['Date', 'de', 'vers'],
      items: [
        {
          isActive: true, Date: '22.06.2020', de: '74429 Sulzbach-Laufen', vers: '66128 Saarbrücken',
        },

      ],
    }
  },
}
</script>
<style>

h2
{
  text-align:center;
 color: #fff;
font-weight: 100;
opacity: 1;

}
.tblrow
{
background-color: rgba(255, 255, 255, 0.616);

font-size:18px;
text-align: center

}
</style>
