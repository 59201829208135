<template>
  <!-- Banner Section Start -->
  <carousel
    class="banner-slider owl-carousel owl-theme"
    :autoplay-direction="'forward'"
    :pagination-position="'top-overlay'"
    :pagination-color="'#efefef'"
    :navigation-enabled="true"
    :autoplay-timeout="5000"
    :autoplay="true"
    :per-page="1"
    :loop="true"
    :mouse-drag="true"
  >
    <slide>
      <div class="banner-item banner-bg-one">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="banner-text">
                    <h1>Vous cherchez un transporteur ?</h1>
                    <p> Avec eBDFRET,vous recevrez en quelques cliques des devis économiques pour toutes vos demandes de transport.
                      Il vous suffit de publier une offre.Et laissez-nous vous trouver des transporteurs fiables et recevez des devis rapidement.
                    </p>

                    <div class="banner-btn">
                      <a
                        href="#"
                        class="default-btn"
                      >Publier une offre</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 get-quote-section">
                  <div class="quote-form">
                    <h2>Trouver un Transporteur</h2>
                    <form>
                      <div class="form-check form-check-inline">
                        <input
                          id="inlineRadio1"
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          value="option1"
                        >
                        <label
                          class="form-check-label"
                          for="inlineRadio1"
                        >Fret routier</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="inlineRadio2"
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          value="option2"
                        >
                        <label
                          class="form-check-label"
                          for="inlineRadio2"
                        >Fret aérien</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="inlineRadio3"
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          value="option3"
                        >
                        <label
                          class="form-check-label"
                          for="inlineRadio3"
                        >Fret maritime</label>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Longueur m"
                              required
                            >
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Hauteur m"
                              required
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Poids kg"
                              required
                            >
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Votre Email"
                              required
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-12">
                          <div class="select-item">
                            <i class="icofont-rounded-down" />
                            <select class="form-control">
                              <option selected>
                                Départ
                              </option>
                              <option>Benin</option>
                              <option>Mali</option>

                            </select>
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="select-item">
                            <i class="icofont-rounded-down" />
                            <select class="form-control">
                              <option selected>
                                Arrivée
                              </option>
                              <option>Benin</option>
                              <option>Mali</option>

                            </select>
                          </div>
                        </div>
                      </div>
                      <button type="submit">
                        Soumettre
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slide>
    <slide>
      <div class="banner-item banner-bg-two">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 get-quote-section">
                  <div class="quote-form">
                    <h2>Trouver des offres de fret
                    </h2>
                    <form>
                      <div class="form-check form-check-inline">
                        <input
                          id="inlineRadio1"
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          value="option1"
                        >
                        <label
                          class="form-check-label"
                          for="inlineRadio1"
                        >Fret routier</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="inlineRadio2"
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          value="option2"
                        >
                        <label
                          class="form-check-label"
                          for="inlineRadio2"
                        >Fret aérien</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="inlineRadio3"
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          value="option3"
                        >
                        <label
                          class="form-check-label"
                          for="inlineRadio3"
                        >Fret maritime</label>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Longeur cm"
                              required
                            >
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Hauteur cm"
                              required
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="poids kg"
                              required
                            >
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="form-group">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Your Email"
                              required
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-12">
                          <div class="select-item">
                            <i class="icofont-rounded-down" />
                            <select class="form-control">
                              <option selected>
                                Pays
                              </option>
                              <option>Mexico</option>
                              <option>Cameroon</option>
                              <option>Slovenia</option>
                              <option>Cameroon</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <div class="select-item">
                            <i class="icofont-rounded-down" />
                            <select class="form-control">
                              <option selected>
                                Pays
                              </option>
                              <option>Armenia</option>
                              <option>Austria</option>
                              <option>Cambodia</option>
                              <option>Malaysia</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <button type="submit">
                        Soumettre
                      </button>
                    </form>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="banner-text">
                    <h1>Vous êtes transporteur?</h1>
                    <p>Avec eBDFRET,chaque jour des centaines de demandes de transporteurs sont publiés.Vous n'avez qu'à consulter les annonces qui correspondent à vos lieux de déplacements pour trouver des chargements et livraison.
                      Enfin,il vous suffit de proposer un devis.</p>
                    <div class="banner-btn">
                      <a
                        href="#"
                        class="default-btn"
                      >Voir des annonces</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slide>
  </carousel>
  <!-- Banner Section End -->
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'Banner',
  components: {
    Carousel, Slide,
  },
}
</script>
