<template>
  <!-- Features Section Start -->
  <div class="features-section">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-duration="1s"
        >
          <div class="feature-card">
            <i class="icofont-fast-delivery" />
            <span>87,357 KM</span>
            <h3>Total livré</h3>
            <p>Ajouter une petite description ici ...</p>
          </div>
        </div>
        <div
          class="col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-duration="1s"
        >
          <div class="feature-card">
            <i class="icofont-location-pin" />
            <span>120</span>
            <h3>Pays livrés</h3>
            <p>Ajouter une petite description ici ...</p>
          </div>
        </div>
        <div
          class="col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-duration="1s"
        >
          <div class="feature-card">
            <i class="icofont-users-alt-3" />
            <span>3.2K</span>
            <h3>Affréteur servis</h3>
            <p>Ajouter une petite description ici ...</p>
          </div>
        </div>
        <div
          class="col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-duration="1s"
        >
          <div class="feature-card">
            <i class="icofont-aim" />
            <span>27</span>
            <h3>Transporteurs</h3>
            <p>Ajouter une petite description ici ...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Features Section End -->
</template>
<script>
export default {
  name: 'FeatureSection',
}
</script>
